<template>
<b-container>
  <div class="my-5">
    <div class="row pr-4 pl-4">
      <div class="row">
          <h4 class="title-job">
            Jobs
          </h4>
          <div style="padding: 0 5px; margin-top: 1.5rem;"> 
            <span class="circle">{{ totalData }}</span> 
          </div>
      </div>
      <div class="col text-right">
        <img src="@/assets/images/search.png" size="18">
        ดูงานทั้งหมด >>
      </div>
    </div>
    <divider />
    <div class="row py-2">
      <div
        class="col-lg-4 col-md-6 col-sm-12 mb-3"
        v-for="(job, index) in jobs"
        :key="index"
      >
        <div class="card h-100">
          <div class="card-head">
            <router-link :to="`/detailjobs/${job._id}`">
              <img
                v-if="job.company && job.company.banners"
                class="card-img-top"
                :src="job.company && job.company.banners"
                :alt="job.title.th"
              />
              <div class="title text-bolder">
                <p>
                  {{ job.company && job.company.name && (job.company.name.th || job.company.name.en) }}
                </p>
              </div>
            </router-link>
          </div>
          <div class="card-body pl-4">
            <router-link :to="`/detailjobs/${job._id}`">
              <p class="card-title font-weight-bolder">{{ job.title.th }}</p>
            </router-link>
            <p class="card-text text-limit">{{ job.descriptions.th }}</p>
          </div>
          <div class="card-bottom pl-4 pr-3 mt-3 mb-2">
              <div class="card-bottom">Salary: {{ job.salary.type === 'range' ?  job.salary.value.join( ' - ') : job.salary.value }}</div>
              <div class="row">
                <div class="col-7 text-limit">Location: {{ job.place.th }}</div>
                <router-link :to="`/detailjobs/${job._id}`" class="col-5 text-right">
                  <button class="btn btn-primary btn-sm">รายละเอียด</button>
                </router-link>
              </div>
            </div>
        </div>
      </div>
    </div>
    <pagination
      :totalData="totalData"
      :totalPage="totalPage"
      :currentPage="currentPage"
      @update:page="
        loadData({ page: $event })
        currentPage = $event
      "
    />
  </div>
</b-container>
</template>

<script>
import { getJobs } from "@/services/job.service";
import Divider from "@/components/Divider.vue"
import Pagination from "@/components/Pagination.vue"
export default {
  components: {
    Divider,
    Pagination
  },
  data() {
    return {
      jobs: [],
      totalData: 0,
      currentPage: 1,
      totalPage: 1,
      limit: 6
    };
  },
  mounted() {
    this.loadData({ page: 1 });
  },
  methods: {
    async loadData(payload) {
      const { data } = await getJobs({ ...payload, limit: this.limit });
      this.jobs = data.data;
      this.totalData = data.total;
      this.currentPage = payload.page;
      this.totalPage = data.total > 0 ? Math.ceil(data.total / this.limit) : 1
    },
  },
};
</script>

<style lang="scss" scoped>
.title-job {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.card {
  background: #ffffff;
  border-radius: 7px;
  transition: all 0.2s ease-in;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 20%);
  .card-head {
    position: relative;
    .card-img-logo {
      position: absolute;
      left: 1em;
      bottom: -5px;
      width: 25%;
      box-shadow: 1px 1px 10px #888888;
    }
    .title {
      position: absolute;
      bottom: 0;
      background: white;
      width: 100%;
      opacity:0.8;
      padding: 10px;
      p {
        color: #004D8D;
        text-align: end;
        margin: 0;
      }
    }
  }
  .card-body {
    padding: 0.5rem 1.25rem 0;
    
    .card-title {
      color: $primary-hard-color;
    }
    
  }
  .card-bottom {
    bottom: 0;
    font-size: 1rem;
    button {
      height: 30px;
      width: 100px;
      font-size: 14px;
    }
  }
}



</style>

import ApiService from "./api.service";


export const getJobs = async (payload) => {
  const response = await ApiService.get("/jobs/recommends/guest", {
    params: { ...payload }
  });

  if(response.data){
    return response
  }
};

export const createJob = async (payload) => {
  return await ApiService.post(
    `/jobs`,
    payload
  );
};

export const getJobCategory = async () => {
  return await ApiService.get(`/jobs/category`);
};
